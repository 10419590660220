import { useCallback, useEffect, useRef, useState } from "react";
import clsx from "~/utils/shared/ClassesUtils";

export default function BrandIcon({ className, color1, color2 }: { className?: string; color1?: string; color2?: string }) {
  return (
    <svg className={clsx(className, "h-full fill-current")} xmlSpace="preserve" viewBox="0 0 712 712">
      <path
        className={clsx(color1 ?? "fill-theme-300", "forked-path")}
        d="M712 356C712 159.39 552.61 0 356 0S0 159.39 0 356c0 186.51 143.43 339.51 326 354.75.59-38.55-.83-63.26-1.43-71.64a116.4 116.4 0 0 1-.49-9.16c0-17.99-75.68-58.96-82.98-117.26-7.29-58.24 44.15-361.31 46.12-376.17 1.92-14.86 8.72-17.06 11.9-16.4 3.13.66 2.41 3.18 2.41 3.18s-8.99 293.03-9.21 301.64c-.27 8.67-.27 17 9.65 17.28 9.98.22 11.19-12.23 11.19-12.23s16.01-281.24 18.21-299.23c2.19-17.99 10.69-3.62 10.69-3.62l.94 298.52s-1.92 18.98 11.19 19.69c17.71-.27 14.81-19.69 14.81-19.69l.99-298.51s8.5-14.37 10.64 3.62C382.82 148.76 398.84 430 398.84 430s1.21 12.45 11.19 12.23c9.93-.27 9.93-8.61 9.71-17.28l-9.21-301.64s-.77-2.52 2.41-3.18c3.13-.66 9.93 1.54 11.9 16.4 1.92 14.86 53.36 317.93 46.07 376.17-7.29 58.3-82.98 99.27-82.98 117.26 0 2.36-.22 5.98-.6 10.42-.16 1.64-1.81 22.1-1.43 70.39C568.51 695.57 712 542.55 712 356z"
      />
      <path
        className={color2 ?? "fill-transparent"}
        d="M387.32 640.37c.38-4.44.6-8.06.6-10.42 0-17.99 75.68-58.96 82.98-117.26 7.29-58.24-44.15-361.31-46.07-376.17-1.97-14.86-8.77-17.06-11.9-16.4-3.18.66-2.41 3.18-2.41 3.18l9.21 301.64c.22 8.67.22 17-9.71 17.28-9.98.22-11.19-12.23-11.19-12.23s-16.01-281.24-18.21-299.23c-2.14-17.99-10.64-3.62-10.64-3.62L369 425.66s2.91 19.41-14.81 19.69c-13.11-.71-11.19-19.69-11.19-19.69l-.93-298.51s-8.5-14.37-10.69 3.62C329.19 148.76 313.17 430 313.17 430s-1.21 12.45-11.19 12.23c-9.93-.27-9.93-8.61-9.65-17.28.22-8.61 9.21-301.64 9.21-301.64s.71-2.52-2.41-3.18c-3.18-.66-9.98 1.54-11.9 16.4-1.97 14.86-53.42 317.93-46.12 376.17 7.29 58.3 82.98 99.27 82.98 117.26 0 2.14.16 5.27.49 9.16.6 8.38 2.02 33.09 1.43 71.64a358.58 358.58 0 0 0 59.89.01c-.39-48.3 1.25-68.76 1.42-70.4z"
      />
    </svg>
  );
}

export function BrandIconTransition({
  className,
  color1,
  color2,
  letters = true,
}: {
  className?: string;
  color1?: string;
  color2?: string;
  letters?: boolean;
}) {
  const [transition, setTransition] = useState(false);
  const [numLetters, setNumLetters] = useState(0);
  const intervalRef = useRef(null);
  const currentNum = useRef();
  currentNum.current = numLetters;

  const forkedLetters = [
    <path d="M21.6 74H0V4h21.6v70Zm28.7-51.6h-40V4h40v18.4Zm-3.7 27H10.3v-17h36.3v17Z" vectorEffect="non-scaling-stroke" key={"f"} />,
    <path
      d="M77.892 73.984A29.855 29.855 0 0 1 71.1 71.4q-6.8-3.6-10.7-9.85a25.84 25.84 0 0 1-3.8-11.803 31.918 31.918 0 0 1-.1-2.547q0-8.1 3.9-14.35T71.1 23a30.986 30.986 0 0 1 11.012-3.346A38.707 38.707 0 0 1 86.6 19.4a35.819 35.819 0 0 1 8.678 1.012A29.449 29.449 0 0 1 102.05 23q6.75 3.6 10.6 9.85a26.027 26.027 0 0 1 3.736 11.62 32.211 32.211 0 0 1 .114 2.73 28.985 28.985 0 0 1-1.185 8.417 25.088 25.088 0 0 1-2.665 5.933q-3.85 6.25-10.6 9.85Q95.3 75 86.6 75a35.983 35.983 0 0 1-8.708-1.016ZM86.6 58q2.7 0 4.85-1.4 2.15-1.4 3.35-3.85a11.777 11.777 0 0 0 1.105-3.858A14.72 14.72 0 0 0 96 47.2a14.498 14.498 0 0 0-.295-2.99 11.322 11.322 0 0 0-.905-2.66 9.724 9.724 0 0 0-2.128-2.899 9.158 9.158 0 0 0-1.222-.951 8.685 8.685 0 0 0-4.808-1.4 10.42 10.42 0 0 0-.042 0 9.512 9.512 0 0 0-2.944.443 8.395 8.395 0 0 0-2.006.957 9.698 9.698 0 0 0-3.199 3.474 11.574 11.574 0 0 0-.201.376 11.331 11.331 0 0 0-1.102 3.539A14.603 14.603 0 0 0 77 47.2a13.187 13.187 0 0 0 .374 3.196 11.021 11.021 0 0 0 .876 2.354 10.198 10.198 0 0 0 2.028 2.763A9.398 9.398 0 0 0 81.65 56.6a8.68 8.68 0 0 0 4.218 1.375A10.711 10.711 0 0 0 86.6 58Z"
      vectorEffect="non-scaling-stroke"
      key={"o"}
    />,
    <path
      d="M146.8 74h-20.3V20.5h18.8l1.2 17.8-4.5-2.8q1.3-4.6 4.1-8.25 2.8-3.65 6.75-5.75t8.45-2.1a23.155 23.155 0 0 1 2.38.118 19.78 19.78 0 0 1 1.02.132q1.6.25 3.1.65l-5.6 22.5q-.902-.632-2.741-1.182a22.876 22.876 0 0 0-.409-.118 16.345 16.345 0 0 0-3.122-.541 19.544 19.544 0 0 0-1.528-.059 10.174 10.174 0 0 0-1.814.155 8.118 8.118 0 0 0-1.436.395 6.017 6.017 0 0 0-1.826 1.075 5.629 5.629 0 0 0-.574.575 7.446 7.446 0 0 0-1.283 2.141 8.733 8.733 0 0 0-.167.459q-.469 1.406-.498 3.252a15.629 15.629 0 0 0-.002.248V74Z"
      vectorEffect="non-scaling-stroke"
      key={"r"}
    />,
    <path
      d="M196.1 74h-20.3V0h20.3v74Zm40.7-53.5L195.7 59l-10-12.6 24.7-25.9h26.4ZM235.4 74h-23.2l-17.8-25.1 14.2-10.2L235.4 74Z"
      vectorEffect="non-scaling-stroke"
      key={"k"}
    />,
    <path
      d="m289.7 47.2-.1 4.2h-41.9l-2.3-9.5h28.1l-1.9 2v-1.8a6.44 6.44 0 0 0-.292-1.969 5.636 5.636 0 0 0-.658-1.381 6.165 6.165 0 0 0-2.237-2.04 7.484 7.484 0 0 0-.413-.21 8.157 8.157 0 0 0-2.102-.647A11.208 11.208 0 0 0 264 35.7a11.685 11.685 0 0 0-2.208.198q-1.408.271-2.538.912a7.322 7.322 0 0 0-.154.09q-2 1.2-3.05 3.4a10.269 10.269 0 0 0-.804 2.508q-.215 1.129-.242 2.42a17.829 17.829 0 0 0-.004.372 14.127 14.127 0 0 0 .375 3.326 11.244 11.244 0 0 0 1.175 2.974 10.231 10.231 0 0 0 3.816 3.78 12.688 12.688 0 0 0 .784.42q2.645 1.301 6.38 1.474a25.323 25.323 0 0 0 1.17.026 20.177 20.177 0 0 0 2.297-.124q1.576-.181 2.853-.626 1.936-.675 4.074-2.121a26.397 26.397 0 0 0 .476-.329l9.3 13.1q-3.7 2.9-7.25 4.5t-7 2.3q-3.45.7-7.05.7a40.308 40.308 0 0 1-7.64-.689 29.259 29.259 0 0 1-8.36-2.911q-6.7-3.6-10.3-9.85a27.014 27.014 0 0 1-3.402-10.655 33.766 33.766 0 0 1-.198-3.695 31.579 31.579 0 0 1 .903-7.656A27.872 27.872 0 0 1 238.5 36.1q2-5.1 5.65-8.85 3.65-3.75 8.6-5.8a27.222 27.222 0 0 1 8.492-1.965 32.393 32.393 0 0 1 2.358-.085 29.453 29.453 0 0 1 6.636.723 24.947 24.947 0 0 1 4.114 1.327q4.85 2.05 8.35 5.8a25.513 25.513 0 0 1 5.233 8.533 29.09 29.09 0 0 1 .117.317 28.254 28.254 0 0 1 1.62 8.125q.081 1.459.03 2.975Z"
      vectorEffect="non-scaling-stroke"
      key={"e"}
    />,
    <path
      d="m337.1 32.2-4.5 1.4V0H353v74h-18.3l-1.6-11.8 4.1.9q-.5 2.6-2.2 4.8a15.829 15.829 0 0 1-3.474 3.285 18.317 18.317 0 0 1-.776.515q-2.55 1.6-5.85 2.5a25.996 25.996 0 0 1-5.854.878 29.677 29.677 0 0 1-1.146.022 20.85 20.85 0 0 1-8.126-1.565 19.903 19.903 0 0 1-3.624-1.985q-5.15-3.55-8.1-9.8a30.354 30.354 0 0 1-2.518-8.458 39.989 39.989 0 0 1-.432-5.992q0-8.1 3-14.4a26.104 26.104 0 0 1 4.288-6.456A22.948 22.948 0 0 1 306.35 23a20.612 20.612 0 0 1 11.206-3.586 25.156 25.156 0 0 1 .844-.014q3.4 0 6.5.95t5.6 2.7q2.5 1.75 4.25 4.1a13.341 13.341 0 0 1 2.134 4.215 12.692 12.692 0 0 1 .216.835ZM324 58.6a10.391 10.391 0 0 0 2.414-.268 7.901 7.901 0 0 0 2.536-1.082 8.078 8.078 0 0 0 2.71-3.025 10.115 10.115 0 0 0 .39-.825q.91-2.167 1.031-5.16a23.222 23.222 0 0 0 .019-.94 20.681 20.681 0 0 0-.173-2.752q-.254-1.884-.877-3.398a9.08 9.08 0 0 0-1.228-2.147 7.373 7.373 0 0 0-1.872-1.703 8.212 8.212 0 0 0-2.975-1.132A11.135 11.135 0 0 0 324 36a10.744 10.744 0 0 0-2.287.231A7.785 7.785 0 0 0 319.1 37.3a7.598 7.598 0 0 0-2.384 2.515 10.008 10.008 0 0 0-.666 1.335 13.054 13.054 0 0 0-.754 2.621Q315 45.388 315 47.3a20.567 20.567 0 0 0 .167 2.694q.251 1.902.883 3.406a9.382 9.382 0 0 0 1.311 2.243 7.815 7.815 0 0 0 1.739 1.607 7.733 7.733 0 0 0 2.932 1.176A10.629 10.629 0 0 0 324 58.6Z"
      vectorEffect="non-scaling-stroke"
      key={"d"}
    />,
  ];

  const addLetter = useCallback(() => {
    if (currentNum.current < forkedLetters.length) {
      setNumLetters(currentNum.current + 1);
    } else {
      clearInterval(intervalRef.current);
    }
  }, [forkedLetters]);

  const startLetters = () => {
    intervalRef.current = setInterval(addLetter, 200);
  };

  useEffect(() => {
    setTimeout(() => setTransition(true), 2000);
    setTimeout(startLetters, 2500);
  }, []);

  return (
    <>
      <div className="max-w-24 h-1/2 w-1/2">
        <svg width={"100%"} height={"100%"} viewBox="0 0 712 2000">
          <ellipse cx="356" cy="-356" ry="356" rx="356" fill="#fff">
            <animate attributeName="cy" from="-356" to="1064" dur="1s" begin="0s" fill="freeze" calcMode="spline" keySplines="0.4 0 0.2 1" keyTimes="0; 1" />

            <animate attributeName="ry" from="356" to="325" dur="0.5s" begin="0.75s" fill="freeze" calcMode="spline" keySplines="0.4 0 0.2 1" keyTimes="0; 1" />
            <animate
              attributeName="cy"
              from="1064"
              to="1095"
              dur="0.5s"
              begin="0.75s"
              fill="freeze"
              calcMode="spline"
              keySplines="0.4 0 0.2 1"
              keyTimes="0; 1"
            />

            <animate attributeName="ry" from="325" to="356" dur="0.5s" begin="1.25s" fill="freeze" calcMode="spline" keySplines="0.4 0 0.2 1" keyTimes="0; 1" />
            <animate
              attributeName="cy"
              from="1095"
              to="1064"
              dur="0.5s"
              begin="1.25s"
              fill="freeze"
              calcMode="spline"
              keySplines="0.4 0 0.2 1"
              keyTimes="0; 1"
            />
            <animate
              attributeName="cy"
              from="1064"
              to="1020"
              dur="0.5s"
              begin="1.5s"
              fill="freeze"
              calcMode="spline"
              keySplines="0.4 0 0.2 1"
              keyTimes="0; 1"
            />

            <animate attributeName="cy" from="1020" to="1644" dur="1s" begin="2s" fill="freeze" calcMode="spline" keySplines="0.4 0 0.2 1" keyTimes="0; 1" />

            {/* <animate attributeName="cy" from="0" to="1644" dur="1s" begin="1s" fill="freeze" calcMode="spline" keySplines="0.4 0 0.2 1" keyTimes="0; 1" /> */}
          </ellipse>
          <g className="translate-y-[1288px]">
            <path
              className={clsx(color2 ?? "fill-theme-300")}
              // className={clsx(color2 ?? "fill-theme-300", "transition-transform", transition ? "translate-y-0" : "translate-y-full")}
              d="M387.32 640.37c.38-4.44.6-8.06.6-10.42 0-17.99 75.68-58.96 82.98-117.26 7.29-58.24-44.15-361.31-46.07-376.17-1.97-14.86-8.77-17.06-11.9-16.4-3.18.66-2.41 3.18-2.41 3.18l9.21 301.64c.22 8.67.22 17-9.71 17.28-9.98.22-11.19-12.23-11.19-12.23s-16.01-281.24-18.21-299.23c-2.14-17.99-10.64-3.62-10.64-3.62L369 425.66s2.91 19.41-14.81 19.69c-13.11-.71-11.19-19.69-11.19-19.69l-.93-298.51s-8.5-14.37-10.69 3.62C329.19 148.76 313.17 430 313.17 430s-1.21 12.45-11.19 12.23c-9.93-.27-9.93-8.61-9.65-17.28.22-8.61 9.21-301.64 9.21-301.64s.71-2.52-2.41-3.18c-3.18-.66-9.98 1.54-11.9 16.4-1.97 14.86-53.42 317.93-46.12 376.17 7.29 58.3 82.98 99.27 82.98 117.26 0 2.14.16 5.27.49 9.16.6 8.38 2.02 33.09 1.43 71.64a358.58 358.58 0 0 0 59.89.01c-.39-48.3 1.25-68.76 1.42-70.4z"
            />
          </g>
        </svg>
      </div>
      {letters && (
        <div className="w-2/3 max-w-md">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 353 75.101" width="100%" height="100%">
            <g fill="#fff" stroke="none" strokeLinecap="round" strokeWidth="0" fontSize="12">
              {forkedLetters.slice(0, numLetters).map((v) => v)}
            </g>
          </svg>
        </div>
      )}
    </>
  );

  //this makes the fork get "uncovered" but that is not the desired effect
  // return (
  //   <>
  //     <svg width={"100%"} height={"100%"} viewBox="0 0 712 712">
  //       <path
  //         className={"fill-white"}
  //         d="M712 356C712 159.39 552.61 0 356 0S0 159.39 0 356c0 186.51 143.43 339.51 326 354.75.59-38.55-.83-63.26-1.43-71.64a116.4 116.4 0 0 1-.49-9.16c0-17.99-75.68-58.96-82.98-117.26-7.29-58.24 44.15-361.31 46.12-376.17 1.92-14.86 8.72-17.06 11.9-16.4 3.13.66 2.41 3.18 2.41 3.18s-8.99 293.03-9.21 301.64c-.27 8.67-.27 17 9.65 17.28 9.98.22 11.19-12.23 11.19-12.23s16.01-281.24 18.21-299.23c2.19-17.99 10.69-3.62 10.69-3.62l.94 298.52s-1.92 18.98 11.19 19.69c17.71-.27 14.81-19.69 14.81-19.69l.99-298.51s8.5-14.37 10.64 3.62C382.82 148.76 398.84 430 398.84 430s1.21 12.45 11.19 12.23c9.93-.27 9.93-8.61 9.71-17.28l-9.21-301.64s-.77-2.52 2.41-3.18c3.13-.66 9.93 1.54 11.9 16.4 1.92 14.86 53.36 317.93 46.07 376.17-7.29 58.3-82.98 99.27-82.98 117.26 0 2.36-.22 5.98-.6 10.42-.16 1.64-1.81 22.1-1.43 70.39C568.51 695.57 712 542.55 712 356z"
  //       />
  //       <g>
  //         <ellipse cx="50%" cy="50%" rx="300" ry="356" fill="#ffffff">
  //           <animate attributeName="ry" from={356} to={0} dur="3s" repeatCount={1} fill="freeze" />
  //           <animate attributeName="rx" from={300} to={100} dur="3s" repeatCount={1} fill="freeze" />
  //           <animate attributeName="cy" from={"50%"} to={"10%"} dur="3s" repeatCount={1} fill="freeze" />
  //         </ellipse>
  //       </g>
  //     </svg>
  //   </>
  // );
}
